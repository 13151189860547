import { Aqua } from '@/aqua/entities/Aqua';
import { useAquaPanel } from '@/aqua/store /useAquaPanelStore';
import { BaseCard } from '@/shared/components/ui/BaseCard';
import { usePanel } from '@/shared/components/ui/Panel/hooks/usePanel';
import TitleWithSite from '@/warehouse/components/TitleWithSite';
import { SiloSite } from '@/warehouse/entities/Silo';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Stack } from 'tamagui';
import AquaConsumptionSimpleChart from '../AquaConsumptionSimpleChart';
import BasicAquaInfo from '../BasicAquaInfo';

type Props = {
    aqua: Aqua;
    site?: SiloSite;
};

const AquaCard = ({ aqua, site }: Props) => {
    const navigation = useNavigation();
    const { isPanel } = usePanel();

    const onSelectAqua = useAquaPanel((state) => state.onSelectAqua);

    function handleOpenDetails() {
        if (isPanel) {
            return onSelectAqua(aqua.id);
        }

        return navigation.navigate('AquaDetails', { aqua });
    }

    return (
        <TouchableOpacity onPress={handleOpenDetails} activeOpacity={0.6}>
            <BaseCard aria-label={aqua.name} flexDirection='row' overflow='hidden' pb={0}>
                <Stack flexGrow={1}>
                    <Stack width='100%'>
                        <TitleWithSite site={site} mb={18}>
                            {aqua.name}
                        </TitleWithSite>

                        <BasicAquaInfo aqua={aqua} resumed />
                    </Stack>

                    <Stack pb={50} mb={-5}>
                        <Stack position='absolute' bottom={0}>
                            <AquaConsumptionSimpleChart consumption={aqua.consumption} />
                        </Stack>
                    </Stack>
                </Stack>
            </BaseCard>
        </TouchableOpacity>
    );
};

export default AquaCard;
