import { Aqua } from '@/aqua/entities/Aqua';
import AquaDetails from '@/aqua/screens/AquaDetails';
import { ChickenHouseInfo } from '@/chicken-house/entities/ChickenHouseInfo';
import EggsReport from '@/chicken-house/screens/EggsReport';
import PlaceEditor from '@/chicken-house/screens/PlaceEditor';
import PlaceScan from '@/chicken-house/screens/PlaceScan';
import { SenseReport } from '@/sense/screens/SenseReport';
import { Silo } from '@/warehouse/entities/Silo';
import FeedManager from '@/warehouse/screens/FeedManager';
import FeedRegister from '@/warehouse/screens/FeedRegister';
import { SiloReport } from '@/warehouse/screens/SiloReport';
import WarehouseDetails from '@/warehouse/screens/WarehouseDetails';
import MaterialIcons from '@expo/vector-icons/MaterialCommunityIcons';
import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Stack } from 'tamagui';
import { AppStack } from '../AppStack';
import { HomeTabs } from './HomeTabs';

export type AuthStackParams = {
    Home: undefined;
    PlaceEditor: undefined;
    WarehouseDetails: {
        silo: Pick<Silo, 'id' | 'name'>;
    };
    WarehouseReport: {
        silo: Pick<Silo, 'id' | 'name'>;
    };
    EggsReport: {
        chickenHouse: Pick<ChickenHouseInfo, 'id' | 'name'>;
    };
    SenseReport: {
        senseId: string;
    };
    FeedManager: undefined;
    FeedRegister?: {
        rationId: string;
    };
    PlaceScan?: {
        from?: keyof AuthStackParams;
    };
    AquaDetails: {
        aqua: Pick<Aqua, 'id' | 'name'>;
    };
};

const AuthStack = () => {
    return (
        <AppStack.Group>
            <AppStack.Screen name='Home' component={HomeTabs} options={{ headerShown: false }} />

            <AppStack.Screen
                name='WarehouseDetails'
                component={WarehouseDetails}
                options={({ route }) => ({
                    title: route.params.silo.name,
                    headerBackVisible: true,
                    headerShadowVisible: false,
                })}
            />

            <AppStack.Screen
                name='FeedManager'
                component={FeedManager}
                options={() => ({
                    title: 'Tipos de ração',
                    presentation: 'modal',
                })}
            />

            <AppStack.Screen
                name='FeedRegister'
                component={FeedRegister}
                options={({ route }) => ({
                    title: route?.params?.rationId ? 'Editar ração' : 'Novo tipo de ração',
                    presentation: 'modal',
                })}
            />

            <AppStack.Screen
                name='PlaceEditor'
                component={PlaceEditor}
                options={({ navigation }) => ({
                    title: 'Configurar aviários',
                    presentation: 'card',
                    headerBackVisible: true,
                    headerRight: () => (
                        <Stack>
                            <TouchableOpacity
                                onPress={() =>
                                    navigation.navigate('PlaceScan', {
                                        from: 'PlaceEditor',
                                    })
                                }
                                accessibilityLabel='Configurar rede'
                            >
                                <MaterialIcons name='cog' size={22} color='#000000' />
                            </TouchableOpacity>
                        </Stack>
                    ),
                })}
            />

            <AppStack.Screen
                name='PlaceScan'
                component={PlaceScan}
                options={() => ({
                    title: 'Configurar rede local',
                    headerBackVisible: true,
                })}
            />

            <AppStack.Screen
                name='WarehouseReport'
                component={SiloReport}
                options={() => ({
                    title: 'Relatório de Silo',
                })}
            />

            <AppStack.Screen
                name='EggsReport'
                component={EggsReport}
                options={() => ({
                    title: 'Relatório de Ovos',
                })}
            />

            <AppStack.Screen
                name='SenseReport'
                component={SenseReport}
                options={() => ({
                    title: 'Relatório de Ambiência',
                })}
            />

            <AppStack.Screen
                name='AquaDetails'
                component={AquaDetails}
                options={({ route }) => ({
                    title: route.params.aqua.name,
                    headerBackVisible: true,
                    headerShadowVisible: false,
                    presentation: 'modal',
                })}
            />
        </AppStack.Group>
    );
};

export default AuthStack;
