import { useAuth } from '@/authentication/store/AuthStore';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import * as FileSystem from 'expo-file-system';
import qs from 'qs';
import { Platform } from 'react-native';
import { alltisApiV2 } from '../../../config/api/alltisApiV2';
import { config } from '../../../config/env';
import { getReportsDirectory } from '../../../config/file-system';

export type UseReportExportProps = {
    entityIds?: string[];
    entityType: 'egg' | 'silo-consumption' | 'silo-resupply' | 'sense';
    startDate?: Date;
    endDate?: Date;
};

type HandleExportOptions = {
    type: ExportReportFileType;
};

export type ExportReportFileType = 'pdf' | 'xlsx' | 'csv' | 'page';

export function useReportExport(filters: UseReportExportProps) {
    const { auth } = useAuth();

    async function handleOpenExportPage() {
        const type = filters.entityType;

        const pageParams = qs.stringify(
            {
                token: auth?.token,
                ids: filters.entityIds,
                startDate: dayjs(filters.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(filters.endDate).format('YYYY-MM-DD'),
                startPeriod: dayjs(filters.startDate).format('HH:mm'),
                endPeriod: dayjs(filters.endDate).format('HH:mm'),
                environment: config.mode === 'production' ? undefined : 'PRERELEASE',
                type,
            },
            {
                arrayFormat: 'comma',
            }
        );

        const pagePath = `/report/export/${type}?${pageParams}`;

        window.open(pagePath);
    }

    async function handleExportPdf() {
        const type = filters.entityType;

        return axios.post<Blob>(
            'https://report.alltis.com.br',
            {
                token: auth?.token,
                ids: filters.entityIds,
                startDate: dayjs(filters.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(filters.endDate).format('YYYY-MM-DD'),
                startPeriod: dayjs(filters.startDate).format('HH:mm'),
                endPeriod: dayjs(filters.endDate).format('HH:mm'),
                environment: config.mode === 'production' ? undefined : 'PRERELEASE',
                type,
            },
            {
                responseType: 'blob',
            }
        );
    }

    function handleExportV2(options: HandleExportOptions) {
        const path = filters.entityType;

        return alltisApiV2.get<Blob>(`/${path}/export`, {
            params: {
                ids: filters.entityIds?.join(),
                initial_period: filters.startDate,
                final_period: filters.endDate,
                type: options.type,
            },
            responseType: 'blob',
        });
    }

    return useMutation({
        mutationKey: ['export-report', filters],
        mutationFn: async (options: HandleExportOptions) => {
            let response: AxiosResponse<Blob, any>;

            if (options.type === 'page') {
                handleOpenExportPage();

                return { uri: '' };
            }

            if (options.type === 'pdf') {
                response = await handleExportPdf();
            } else {
                response = await handleExportV2(options);
            }

            const uri = await saveFile(response.data, options.type);

            return { uri };
        },
    });

    async function saveFile(pdfBlob: Blob, ext: string) {
        const filename = `Relatorio ${dayjs().format('DD MM YYYY HH mm ss')}.${ext}`.replaceAll(' ', '-');

        if (Platform.OS === 'web') {
            const url = URL.createObjectURL(pdfBlob);

            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            a.download = filename;
            document.body.appendChild(a);

            a.click();

            return url;
        }

        const base64 = await readFile(pdfBlob);

        const reportFileUri = await getReportsDirectory(filename);

        await FileSystem.writeAsStringAsync(reportFileUri, base64.split(',')[1], {
            encoding: FileSystem.EncodingType.Base64,
        });

        return reportFileUri;
    }

    function readFile(blob: Blob) {
        return new Promise<string>((resolve, reject) => {
            const fr = new FileReader();

            fr.onload = () => {
                resolve(fr.result as string);
            };

            fr.onerror = reject;

            fr.readAsDataURL(blob);
        });
    }
}
