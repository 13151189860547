import { ConfigCatProvider, createConsoleLogger, LogLevel, PollingMode } from 'configcat-react';
import { PropsWithChildren } from 'react';
import { MMKV } from 'react-native-mmkv';
import { config } from '../env';

const featureFlagStorage = new MMKV({ id: '@alltis/feature-flags-db' });

export const FeatureFlagProvider = ({ children }: PropsWithChildren) => {
    const logger = __DEV__ ? createConsoleLogger(LogLevel.Info) : undefined;

    return (
        <ConfigCatProvider
            sdkKey={config.configCat.sdkKey}
            options={{
                logger,
                pollIntervalSeconds: 60,
                cacheTimeToLiveSeconds: config.mode === 'production' ? 60 : 10,
                cache: {
                    get: (key) => featureFlagStorage.getString(key),
                    set: (key, value) => featureFlagStorage.set(key, value),
                },
            }}
            pollingMode={PollingMode.AutoPoll}
        >
            {children}
        </ConfigCatProvider>
    );
};
